import { toDate, endOfDay } from '@/utils/date'
import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'

export default {
  async selectParteNovedad (Vue, filter, search, sorter, page, idpuestoServicio) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addExact('idpuesto_servicio', idpuestoServicio)
      .addGT('estado', 0)
    if (search) {
      const apiFilterSearch = new APIFilter()
      apiFilterSearch
        .setOperator(API_FILTER_OPERATORS.OR)
        .addILike('observaciones', search)
      apiFilter.addNestedFilter(apiFilterSearch)
    }
    if (filter.fdesde.value) {
      apiFilter.addGTE(filter.fdesde.field, toDate(filter.fdesde.value))
    }
    if (filter.fhasta.value) {
      apiFilter.addLTE(filter.fhasta.field, endOfDay(toDate(filter.fhasta.value)))
    }
    const resp = await Vue.$api.call('parteNovedad.select', {
      page,
      filter: apiFilter,
      sorter,
    })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectParteNovedadRows (Vue, pks, idpuestoServicio) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addExact('idpuesto_servicio', idpuestoServicio)
      .addGT('estado', 0)
      .addIn('idparte_novedad', pks)
    const resp = await Vue.$api.call('parteNovedad.select', { filter: apiFilter })
    return [resp.data.result.dataset, resp.data.result.metadata]
  }
}
